import { IconProp } from "@fortawesome/fontawesome-svg-core"
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome"
import IconRegistry from "../utils/registry"
import { ComponentProps } from "react"

const types = {
  regular: "far",
  solid: "fas",
  light: "fal",
  duotone: "fad",
}

export type IconType = keyof typeof types

export const replaceTypeString = (icon: string, type?: IconType) => {
  return icon.replace(/(^[a-z]*)/, types[type] ?? "fa")
}

const DynamicResolve = ({ icon, ...props }: { icon: string }) => {
  if (icon?.startsWith("fa")) {
    let type = "regular"

    if (icon.startsWith("fas")) type = "solid"
    if (icon.startsWith("far")) type = "regular"
    if (icon.startsWith("fal")) type = "light"
    if (icon.startsWith("fad")) type = "duotone"

    const registry = IconRegistry.getIcons(type as IconType)
    if (!registry) return null

    icon = registry[replaceTypeString(icon)]

    if (icon) return <FontAwesomeIcon icon={icon as IconProp} {...props} />

    return
  }

  return
}

export type TIcon = ComponentProps<typeof Icon>

export const Icon = ({
  icon,
  ...props
}: Omit<FontAwesomeIconProps, "icon"> & {
  icon: string | { iconName?: string; iconname?: string; prefix?: string }
}) => {
  if (icon && typeof icon === "object") {
    icon =
      icon.prefix +
      (icon?.iconName ?? icon?.iconname ?? "").replace(/-|\b\w/g, (match) => {
        return match === "-" ? "" : match.toUpperCase()
      })
  }
  return <DynamicResolve icon={icon as string} {...props} />
}
