/** @type {import('next-i18next').UserConfig} */
const path = require("path")
const HttpBackend = require("i18next-http-backend/cjs")
const ChainedBackend = require("i18next-chained-backend").default
const LocalStorageBackend = require("i18next-localstorage-backend").default

const isBrowser = typeof window !== "undefined"
const isDev = process.env.NODE_ENV === "development"

// If development, we don't cache the translations, otherwise set the cache to 1 hour
const expirationTime = isDev ? 0 : 60 * 60 * 1000

module.exports = {
  // debug: isDev,
  backend: {
    backendOptions: [
      { expirationTime },
      {
        /* loadPath: 'https:// somewhere else' */
      },
    ], // 1 hour
    backends: isBrowser ? [LocalStorageBackend, HttpBackend] : [],
  },
  // partialBundledLanguages: isBrowser && true,
  i18n: {
    defaultLocale: "en",
    locales: ["en", "da", "no"],
    localePath: path.resolve("./public/locales"),
  },
  serializeConfig: false,
  use: isBrowser ? [ChainedBackend] : [],
}
