import { appsAtom } from "@/atoms/apps"
import { isSubspace, selectedSpaceAtom, spacesAtom } from "@/atoms/spaces"
import { meAtom } from "@/atoms/users"
import {
  //  IconDefinition,
  faBarsStaggered,
  faBriefcase,
  faBuildings,
  faChartMixed,
  faFileContract,
  faGear,
  faStopwatch,
  faUsers,
} from "@awesome.me/kit-44b29310a6/icons/duotone/solid"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { Popover } from "antd"
import Link from "next/link"
import { useRouter } from "next/router"
import {
  CSSProperties,
  HTMLAttributes,
  MouseEvent,
  MouseEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useMediaQuery } from "react-responsive"
import { useRecoilValue, useRecoilValueLoadable } from "recoil"
import { Icon } from "../../features/icons/components/IconLoader"
import { PagesAtom } from "../../features/page/atoms"
import { COMPANIES_APP } from "../../utils/api/apps"
import { Loader } from "../spinners/loader"
import TasksSidebar from "@/modules/tasks/components/TasksSideBar"
//import ProjectsSideBar from "./ProjectsSideBar"

const OFFSET_TOP = 112

export const SideBar = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const [slider, setSlider] = useState<number>(OFFSET_TOP)
  const router = useRouter()
  const { pathname, asPath } = router
  const { contents: apps, state } = useRecoilValueLoadable(appsAtom)
  const timer = useRef<NodeJS.Timeout>()
  const activeOffset = useRef<number>(OFFSET_TOP)
  const user: any = useRecoilValue(meAtom)
  const space = useRecoilValue(selectedSpaceAtom)
  const isSupplier = useRecoilValue(isSubspace)
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false)
  const sidebarRef = useRef(null)
  const { contents: spaces, state: stateSpace } =
    useRecoilValueLoadable(spacesAtom)

  const organisationSettings = useMemo(() => {
    return (
      stateSpace === "hasValue" &&
      spaces.find((s) => s?.uid === space.organisation?.uid)?._settings
    )
  }, [stateSpace, spaces, space])

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false)
    }
  }

  const { contents: pages, state: statePage } =
    useRecoilValueLoadable(PagesAtom)

  useEffect(() => {
    const rect = getElement()
    if (rect) {
      setSlider(rect.top)
      activeOffset.current = rect.top
    } else {
      setSlider(0)
    }
  }, [pages])

  const onMouseEnter = (e: MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect()
    clearTimeout(timer.current)
    setSlider(rect.top)
  }

  const onMouseLeave = () => {
    const rect = getElement()
    timer.current = setTimeout(() => {
      if (rect) {
        setSlider(activeOffset.current)
      } else {
        setSlider(0)
      }
    }, 400)
  }

  useEffect(() => {
    const activeTab = asPath.split("/")[1]
    const elem = document.getElementById(activeTab)

    if (elem) {
      const rect = elem.getBoundingClientRect()
      setSlider(rect.top)
      activeOffset.current = rect.top
    } else {
      setSlider(0)
    }
  }, [asPath])

  const getElement = () => {
    const activeTab = asPath.split("/")[1]
    const elem = document.getElementById(activeTab)
    return elem?.getBoundingClientRect()
  }

  const renderSideBarIcon = (
    id: string,
    icon: IconDefinition,
    active: boolean,
    href: string,
  ) => {
    return (
      <SideBarIcon
        key={id}
        id={id}
        icon={icon}
        active={active}
        href={href}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    )
  }

  const renderPopoverSideBarIcon = (
    id: string,
    icon: IconDefinition,
    href: string,
    content: string,
  ) => {
    return (
      <Popover key={id} content={content} placement="right">
        <SideBarIcon
          id={id}
          icon={icon}
          active={false}
          href={href}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className="cursor-pointer opacity-30"
        />
      </Popover>
    )
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const showSettings = (): boolean => {
    return user?.is_employee === true || isSupplier === true ? true : false
  }

  if (statePage !== "hasValue" || state !== "hasValue") {
    return <Loader />
  }

  let content: JSX.Element = <></>
  if (user?.is_employee === false) {
    content = (
      <>
        <SideBarIcon
          id="projects"
          icon={faBriefcase}
          active={pathname.startsWith("/projects") || isSidebarOpen}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
        {isSupplier &&
        apps.some((app) => app.tags.includes("Time_Registration"))
          ? renderSideBarIcon(
              "time_registration",
              faStopwatch,
              pathname.startsWith("/time_registration"),
              "/time_registration",
            )
          : isSupplier
            ? organisationSettings?.hide_supplier_sales_nav
              ? null
              : renderPopoverSideBarIcon(
                  "time_registration",
                  faStopwatch,
                  "/sales",
                  "Time registration: Contact sales",
                )
            : null}
        {renderSideBarIcon(
          "contracts",
          faFileContract,
          pathname.startsWith("/contracts"),
          "/contracts",
        )}
        <div className="mb-12 mt-auto h-16 w-full">
          <SideBarIcon
            id="settings"
            icon={faGear}
            active={pathname.startsWith("/settings")}
            href={"/settings"}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        </div>
      </>
    )
  } else {
    content = (
      <>
        {slider !== 0 && (
          <div
            style={{ top: slider }}
            className={`absolute left-0 h-16 w-[6px] rounded-br-md rounded-tr-md bg-blue-500 transition-all group-hover:scale-100`}
          />
        )}

        {space?._settings?.show_demo_analytics &&
          apps?.some((app) => app.tags.includes("Analytics")) &&
          renderSideBarIcon(
            "analytics",
            faChartMixed,
            pathname.startsWith("/old_analytics"),
            "/old_analytics",
          )}

        {isSupplier
          ? organisationSettings?.hide_supplier_sales_nav
            ? null
            : renderPopoverSideBarIcon(
                "analytics",
                faChartMixed,
                "/sales",
                "Analytics: Contact sales",
              )
          : null}

        {state === "hasValue" &&
          pages
            ?.filter((p) => p?.status === "active")
            ?.map((page) =>
              renderSideBarIcon(
                page.path.split("/")[1],
                page.icon,
                asPath === page.path,
                page.path,
              ),
            )}

        {apps?.some((app) => app.name === "Timeline") &&
          renderSideBarIcon(
            "timeline",
            faBarsStaggered,
            pathname.startsWith("/timeline"),
            "/timeline",
          )}

        {apps?.some((app) => app.tags.includes("People"))
          ? renderSideBarIcon(
              "people",
              faUsers,
              pathname.startsWith("/people"),
              "/people",
            )
          : isSupplier
            ? organisationSettings?.hide_supplier_sales_nav
              ? null
              : renderPopoverSideBarIcon(
                  "people",
                  faUsers,
                  "/sales",
                  "Manage your people: Contact sales",
                )
            : null}

        <SideBarIcon
          id="projects"
          icon={faBriefcase}
          active={pathname.startsWith("/projects") || isSidebarOpen}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />

        {apps.some((app) => app.tags.includes("Time_Registration"))
          ? renderSideBarIcon(
              "time_registration",
              faStopwatch,
              pathname.startsWith("/time_registration"),
              "/time_registration",
            )
          : isSupplier
            ? organisationSettings?.hide_supplier_sales_nav
              ? null
              : renderPopoverSideBarIcon(
                  "time_registration",
                  faStopwatch,
                  "/sales",
                  "Time registration: Contact sales",
                )
            : null}

        {renderSideBarIcon(
          "contracts",
          faFileContract,
          pathname.startsWith("/contracts"),
          "/contracts",
        )}

        {apps.some((app) => app.uid === COMPANIES_APP)
          ? renderSideBarIcon(
              "companies",
              faBuildings,
              pathname.startsWith("/companies"),
              "/companies",
            )
          : isSupplier
            ? organisationSettings?.hide_supplier_sales_nav
              ? null
              : renderPopoverSideBarIcon(
                  "companies",
                  faBuildings,
                  "/sales",
                  "Manage companies and deals: Contact sales",
                )
            : null}

        {showSettings() === true && (
          <div className="my-auto mt-auto w-full md:my-0 md:mb-12 md:h-16">
            <SideBarIcon
              id="settings"
              icon={faGear}
              active={pathname.startsWith("/settings")}
              href={"/settings"}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          </div>
        )}
      </>
    )
  }

  const getProjectsSideBar = () => {
    if (isSidebarOpen === false) {
      return (
        <>
          <div></div>
        </>
      )
    }
    return (
      <TasksSidebar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
    )
  }

  if (isMobile) {
    return (
      <div>
        <div className="absolute top-16 z-40 h-full bg-white">
          {getProjectsSideBar()}
        </div>
        <div className="fixed bottom-0 z-50 flex h-16 w-full flex-row items-center gap-4 overflow-x-auto overflow-y-hidden border-r border-t bg-white px-4">
          {content}
        </div>
      </div>
    )
  }

  return (
    <aside
      ref={sidebarRef}
      className={`z-50 flex w-fit border-r bg-white transition-all`}
    >
      <div className="z-10 flex max-h-full w-16 flex-col items-center gap-4 border-r bg-inherit px-4 pt-12">
        {content}
      </div>
      {getProjectsSideBar()}
    </aside>
  )
}

interface ISideBarIcon extends HTMLAttributes<HTMLAnchorElement> {
  icon: IconDefinition
  active: boolean
  href?: string
  onClick?: () => void
}

const SideBarIcon = ({
  className,
  icon,
  active,
  href,
  onClick,
  ...props
}: ISideBarIcon) => {
  const activeStyle = {
    "--fa-primary-color": "var(--color-blue-500)",
    "--fa-secondary-color": "var(--color-blue-500)",
  } as CSSProperties
  const inactiveStyle = {
    "--fa-primary-color": "#79879B",
    "--fa-secondary-color": "#79879B",
  } as CSSProperties

  if (!href) {
    return (
      <div
        className="h-16 w-full cursor-pointer"
        onClick={onClick}
        onMouseEnter={
          props.onMouseEnter as unknown as MouseEventHandler<HTMLDivElement>
        }
        onMouseLeave={
          props.onMouseLeave as unknown as MouseEventHandler<HTMLDivElement>
        }
      >
        <div className="group relative flex h-full w-full items-center justify-center">
          <Icon
            size="2x"
            icon={icon}
            className={className}
            style={active ? activeStyle : inactiveStyle}
          />
        </div>
      </div>
    )
  }
  return (
    <Link {...props} className="h-16 w-full" href={href} onClick={onClick}>
      <div className="group relative flex h-full w-full items-center justify-center">
        <Icon
          size="2x"
          icon={icon}
          className={className}
          style={active ? activeStyle : inactiveStyle}
        />
      </div>
    </Link>
  )
}

export default SideBar
